import React, { useState } from "react"
import {
  forgotPasswordRequest,
  forgotPasswordReset,
} from "../services/forgot-password"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import { FormProvider, useForm } from "react-hook-form"
import Input from "../components/form/Input"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { navigate } from "gatsby"

const ForgotPasswordPage = ({ component: Component, location, ...rest }) => {
  const [email, setEmail] = useState("")
  const [isChangePassword, setIsChangePassword] = useState(false)

  if (isChangePassword) {
    return <ChangePasswordForm email={email} />
  }

  return (
    <ForgotPasswordForm
      setIsChangePassword={setIsChangePassword}
      setEmail={setEmail}
    />
  )
}

const ForgotPasswordForm = ({ setIsChangePassword, setEmail }) => {
  const schema = yup
    .object({
      email: yup
        .string()
        .email("Email must be a valid email")
        .required("Email is required"),
    })
    .required()

  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  const onSubmit = async (data) => {
    forgotPasswordRequest(data).then((res) => {
      setIsChangePassword(true)
      setEmail(data.email)
    })
  }

  return (
    <Layout>
      <Container>
        <h1>Forgot Password</h1>
        <p>
          Enter your email address and we will send you a code to reset your
          password
        </p>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Input
              label={"Email"}
              name="email"
              {...register("email")}
              required
              placeholder={"Your Email"}
              errorMessage={errors.email?.message}
            />
            <button type="submit">Submit</button>
          </form>
        </FormProvider>
      </Container>
    </Layout>
  )
}

const ChangePasswordForm = ({ email }) => {
  const schema = yup
    .object({
      otp: yup.string().required("Code is required"),
      new_password: yup.string().required("Password Name is required"),
      confirm_password: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("new_password"), null], "Passwords must match"),
    })
    .required()

  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  const [generalMsg, setGeneralMsg] = useState("")

  const onSubmit = async (data) => {
    data.email = email
    forgotPasswordReset(data).then((res) => {
      if (res.status) {
        navigate(`/account`)
      } else {
        setGeneralMsg(res.message)
      }
    })
  }

  return (
    <Layout>
      <Container>
        <h1>Change Password</h1>
        <p>To change your password, use the OTP we sent you to your email.</p>

        {!!generalMsg && <p className="input--error">{generalMsg}</p>}

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Input
              label={"OTP"}
              name="otp"
              {...register("otp")}
              required
              placeholder={"Enter the OTP"}
              errorMessage={errors.otp?.message}
            />
            <Input
              label={"New Password"}
              name="new_password"
              type="password"
              {...register("new_password")}
              required
              placeholder={"New Password"}
              errorMessage={errors.new_password?.message}
            />
            <p>
              Password must be between 6-12 characters including one capital,
              one small letter and one number.
            </p>
            <Input
              label={"Confirm Password"}
              name="confirm_password"
              type="password"
              {...register("confirm_password")}
              required
              placeholder={"Confirm New Password"}
              errorMessage={errors.confirm_password?.message}
            />
            <button type="submit">Submit</button>
          </form>
        </FormProvider>
      </Container>
    </Layout>
  )
}

export default ForgotPasswordPage
